<template>
  <div>
    <div style="padding: 20px">
      <div class="handle-box">
        <el-button type="primary" class="handle-del mr10" @click="addVisible = true">新增校区</el-button>

        <el-select v-model="query.regionId" placeholder="城市" class="handle-select mr10">
          <el-option v-for="(item,index) in regionList" :key="index" :label="item.region" :value="item.id"></el-option>
        </el-select>
        <el-input v-model="query.keyword" placeholder="校区名称" class="handle-input mr10"></el-input>

        <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>

        <el-button type="info" icon="Search" @click="reset">重置</el-button>
        <el-popconfirm @confirm="exportExcel"
                       title="确定要导出吗？"
        >
          <template #reference>
            <el-button type="primary" size="small" icon="Download">导出</el-button>
          </template>
        </el-popconfirm>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :row-style="{height:0+'px'}"
          :cell-style="{padding:0+'px'}"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            width="120"
            show-overflow-tooltip="true"
            prop="campusname"
            label="校区名称"></el-table-column>
        <el-table-column
            width="160"
            show-overflow-tooltip="true"
            prop="location"
            label="详细地址"
        ></el-table-column>
        <el-table-column
            width="180"
            show-overflow-tooltip="true"
            prop="longitude"
            label="所在经度"
        ></el-table-column>
        <el-table-column
            width="180"
            show-overflow-tooltip="true"
            prop="latitude"
            label="所在维度"
        ></el-table-column>
        <el-table-column
            width="120"
            show-overflow-tooltip="true"
            prop="flag"
            label="校区状态"
        ></el-table-column>
        <el-table-column
            width="110"
            show-overflow-tooltip="true"
            label="校区负责人">
          <template #default="scope">{{ scope.row.coach == null ? '暂无负责人' : scope.row.coach.coachname }}</template>
        </el-table-column>
        <el-table-column
            width="160"
            show-overflow-tooltip="true"
            prop="businessTime"
            label="上课时间"></el-table-column>
        <el-table-column
            width="110"
            label="所属城市">
          <template #default="scope">{{ scope.row.region != null ? scope.row.region.region : '' }}</template>
        </el-table-column>
        <el-table-column
            width="110"
            align="center"
            label="校区图片">
          <template v-slot="scope">
            <el-image
                class="code"
                :src="scope.row.campusImg != null? JSON.parse(scope.row.campusImg)[0]:null"
                :preview-src-list="scope.row.campusImg != null?JSON.parse(scope.row.campusImg):null">
              <template #error>
                <div class="code">
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column
            label="状态"
            width="110">
          <template #default="scope">
            <el-tooltip :content="scope.row.state=='开启'? '关闭后此教练将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="setState(scope.row.id,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="关闭"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改校区" v-model="editVisible" width="40%">
      <el-form :rules="rules" ref="ruleForm" :model="form" label-width="100px">
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="campusname" label="校区名称">
            <el-input v-model="form.campusname"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="regionid" label="所属城市">
            <el-select v-model="form.regionid" disabled placeholder="城市" @change="selectCity2">
              <el-option v-for="(item,index) in regionList" :key="index" :label="item.region"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="sort" label="排序">
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="principalId" label="负责人">
            <el-select v-model="form.principalId" filterable placeholder="请选择" @change="selectCity2">
              <el-option v-for="(item,index) in coachList" :key="index" :label="item.coachname+item.coachphone"
                         :value="item.coachid"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="longitude" label="校区经度">
            <el-input v-model="form.longitude"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="latitude" label="校区维度">
            <el-input v-model="form.latitude"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="flag" label="校区状态">
            <el-select v-model="form.flag" placeholder="状态" class="handle-select mr10">
              <el-option key="开课中" label="开课中" value="开课中"></el-option>
              <el-option key="停课中" label="停课中" value="停课中"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 50%" prop="location" label="详细地址">
            <el-input v-model="form.location"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="businessTime" label="营业时间">
            <el-input v-model="form.businessTime"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="campusImg" label="校区风采">
            <el-input v-model="form.campusImg"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 新增弹出框框 -->
    <el-dialog title="校区信息录入" v-model="addVisible" width="40%">
      <el-form  :rules="rules" ref="ruleForm" :model="addForm" label-width="100px">
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="campusname" label="校区名称">
            <el-input v-model="addForm.campusname"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="regionid" label="所属城市">
            <el-select v-model="addForm.regionid" placeholder="城市">
              <el-option @click="selectCity2(item)" v-for="(item,index) in regionList" :key="index" :label="item.region"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="sort" label="排序">
            <el-input v-model="addForm.sort"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="principalId" label="负责人">
            <el-select v-model="addForm.principalId" filterable placeholder="请选择" @change="selectCity2">
              <el-option v-for="(item,index) in coachList" :key="index" :label="item.coachname+item.coachphone"
                         :value="item.coachid"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="longitude" label="校区经度">
            <el-input v-model="addForm.longitude"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="latitude" label="校区维度">
            <el-input v-model="addForm.latitude"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item prop="flag" label="状态">
            <el-select v-model="addForm.flag" placeholder="状态" class="handle-select mr10">
              <el-option key="开课中" label="开课中" value="开课中"></el-option>
              <el-option key="停课中" label="停课中" value="停课中"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 50%" prop="location" label="详细地址">
            <el-input v-model="addForm.location"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;padding-right: 20px">
          <el-form-item style="width: 50%" prop="businessTime" label="营业时间">
            <el-input v-model="addForm.businessTime"></el-input>
          </el-form-item>
          <el-form-item style="width: 50%" prop="campusImg" label="校区风采">
            <el-input v-model="addForm.campusImg"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd('ruleForm')">确 定</el-button>
                </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import {editState, selectCoachById, exportExcel} from "@/api/campus";
import {campusList, addCampus, editSort} from "@/api/campus";
// import {cityList} from "@/api/public";

export default {
  name: "Campus",
  data() {
    return {
      query: {
        keyword: "",
        regionId: "",
        campusId: "",
        pageIndex: 1,
        pageSize: 10
      },
      rules: {
        campusname: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        longitude: [
          {required: true, message: '请输入校区的经度', trigger: 'blur'},
        ],
        latitude: [
          {required: true, message: '请输入校区的维度', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '请输入校区状态', trigger: 'blur'},
        ],
        location: [
          {required: true, message: '请输入校区详细地址', trigger: 'blur'},
        ],
        businessTime: [
          {required: true, message: '请输入校区营业时间', trigger: 'blur'},
        ],
        // campusImg: [
        //  {required: true, message: '请输入校区图片路径', trigger: 'blur'},
        // ],
        regionid: [
          {required: true, message: '请选择所属城市', trigger: 'change'}
        ],
        principalId: [
          {required: true, message: '请选择校区负责人', trigger: 'change'}
        ]
      },
      loading: false,
      regionList: [],
      coachList: [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      addForm: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
    // 城市下拉数据
    // cityList().then(res => {
    //   if (res.code == 200) {
    //     this.regionList = res.data
    //   } else {
    //     this.$message.error("获取城市数据失败");
    //   }
    // })
  },
  methods: {
    // 获取数据
    getData() {
      // 表格数据
      campusList(this.query).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }).catch(() => {
      })
    },

    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex = 1
      this.getData();
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      // 校区数据
      selectCoachById(row.regionid).then(val => {
        if (val.code == 200) {
          this.coachList = val.data
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    //取消编辑
    qx() {
      this.editVisible = false;
      this.$message.info(`您取消了修改`);
    },
    // 保存编辑
    saveEdit() {
      editSort(this.form).then(res => {
        this.editVisible = false;
        if (res.code == 200) {
          if (res.data > 0) {
            this.getData()
            this.$message.success(`修改 成功`);
          } else {
            this.$message.error(`修改失败`);
          }
        } else {
          this.$message.error(`修改 失败`);
        }
      })

    },

    /**
     * 新增
     */
    saveAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addCampus(JSON.parse(JSON.stringify(this.addForm))).then(res => {
            this.addVisible = false;
            console.log(res);
            if (res.code == 200) {
              this.getData()
              this.$message.success(`新增校区: ${this.addForm.campusname} 成功`);
            } else {
              this.$message.error(`新增校区: ${this.addForm.campusname} 失败`);
            }
          })
        }
      })
    },
    // 导出excel
    exportExcel() {
      // 二次确认删除
      this.loading = true
      exportExcel(this.query).then(res => {
        this.loading = false
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a'); //我们用模拟q标签点击事件
        //var myDate = new Date(); //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', "校区");
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },

    // 编辑 选中城市  渲染校区
    selectCity2(val) {
      console.log(val)
      this.form.campusid = null
      this.addForm.campusid = null
      selectCoachById(val.id).then(val => {
        if (val.code == 200) {
          this.coachList = val.data
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
    },
    // 修改教练状态
    setState(id, state) {
      editState(id, state).then(res => {
        if (res.code == 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })
    },
    // 重置
    reset() {
      this.query.regionId = ""
      this.query.campusId = ""
      this.query.type = ""
      this.query.keyword = ""
      this.query.pageIndex = 1
      this.getData()
    }
  }
};
</script>

<style scoped>
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
