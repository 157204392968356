import request from '../utils/request';

/**
 * 获取城市列表
 * @param query
 * @returns {AxiosPromise}
 */
export const campusList = (query) => {
    return request({
        url: '/sys/campus/campusList',
        method: 'get',
        params:query
    });
};

/**
 * 新增城市
 * @param form
 * @returns {AxiosPromise}
 */
export const addCampus = (form) => {
    return request({
        url: '/sys/campus/addCampus',
        method: 'post',
        data:form
    });
};
/**
 * 修改校区排序
 * @param id
 * @returns {AxiosPromise}
 */
export const editSort = (campus) => {
    return request({
        url: '/sys/campus/editCampus',
        method: 'post',
        data:campus
    });
};
/**
 * 修改校区状态
 * @param coachId
 * @param state
 * @returns {AxiosPromise}
 */
export const editState = (id,state) => {
    return request({
        url: '/sys/campus/editState',
        method: 'get',
        params:{
            id,
            state
        }
    });
};
/**
 * 角色列表
 * @returns {AxiosPromise}
 */
export const selectCoachById = (regionId) => {
    return request({
        url: '/sys/coach/selectCoachById',
        method: 'get',
        params:{
            regionId
        }
    });
};
/***
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcel(query) {
    return request({
        url: '/sys/campus/excel',
        method: 'get',
        params: query,
        responseType: 'arraybuffer'
    })
}